import req from "./request";

export default {
  index: async () => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      let url = `visualizations?building_id=${building.id}`;
      const { data } = await req.get(url, true);
      return data;
    } catch (error) {
      throw error;
    }
  },
  show: async (id) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const { data } = await req.get(
        `visualizations/${id}?building_id=${building.id}`,
        true
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  download: async (id) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const { code } = await req.get(
        `visualizations/${id}/download?building_id=${building.id}`,
        true
      );
      return code;
    } catch (error) {
      throw error;
    }
  },
  store: async (data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      const response = await req.post(
        `visualizations?building_id=${building.id}`,
        data,
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  update: async (id, data) => {
    try {
      const building = JSON.parse(localStorage.getItem("building")) || null;

      const response = await req.put(
        `visualizations/${id}?building_id=${building.id}`,
        data,
        true
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
